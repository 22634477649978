<template>
  <div class="info-box">
    <div class="info-box-content">
      <div
        class="info-text-img-container"
        v-for="(infoElement, index) in infoElements"
        :key="index"
      >
        <img
          :src="require(`@/assets/img/icons/${infoElement.icon}.svg`)"
          alt="info icon"
        />
        <p>
          {{ infoElement.description }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    infoElements: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style scoped>
.info-box {
  font-size: 1.6rem;
  background-color: #e6fcf5;
  color: #087f5b;
  border: 1px solid #c3fae8;
  border-radius: 9px;
  padding: 1.2rem;
  display: flex;
  gap: 7px;
  line-height: 150%;
  font-weight: 500;
  align-items: center;
  max-width: 100%;
  width: auto;
  word-wrap: break-word;
}

.info-box-content {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.info-text-img-container {
  display: flex;
  gap: 1rem;
}
</style>
