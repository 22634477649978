<template>
  <main class="">
    <section class="section-hero">
      <div class="hero">
        <div
          class="order-in-progress-container"
          v-if="pendingOrders.length > 0"
        >
          <OrderInProgressPreviewComponent :order="pendingOrders[0]" />
          <router-link class="more-orders-description" :to="{ name: 'orders' }">
            <span v-if="pendingOrders.length > 1">
              Más de un pedido en curso (ver pedidos)
            </span>
          </router-link>
        </div>
        <div class="hero-description-box">
          <h1 class="heading-primary">
            <span>Frutos Secos</span>
            <span>Deshidratados</span>
            <span>Semillas</span>
          </h1>
          <div class="hero-specs">
            <div class="hero-spec">
              <img
                draggable="false"
                src="@/assets/img/icons/location.svg"
                alt="icono de ubicacion"
              />
              <span>Colombia</span>
            </div>

            <div class="hero-spec">
              <img
                draggable="false"
                src="@/assets/img/icons/delivery.svg"
                alt="icono envios"
              />
              <span>Envíos</span>
            </div>

            <div class="hero-spec">
              <img
                draggable="false"
                src="@/assets/img/icons/leaf.svg"
                alt="icono de hoja"
              />
              <span>Alimento Sano</span>
            </div>
          </div>
          <div class="input-search-container" ref="inputSearchContainer">
            <BigInputSearchComponent
              v-if="!showInputSearchInHeader || isMobile"
            />
            <GoToPaymentButtonComponent
              id="btn-cta-home"
              v-if="cartLength > 0"
            />
          </div>
        </div>
      </div>
    </section>

    <section class="section-catalog" id="catalog">
      <section class="section" id="nuts">
        <div
          class="products-not-found-info"
          v-if="filteredProductsLength === 0"
        >
          <InfoBoxComponent
            :info-elements="[
              {
                icon: 'info',
                description:
                  'No se encontraron productos con el filtro especificado',
              },
            ]"
          />
        </div>
        <div v-if="products" class="container grid grid--4-cols">
          <ProductCardComponent
            v-for="product in products"
            :key="product.name"
            :product="product"
            :filter="filter"
          />
        </div>
        <div v-else class="container grid grid--4-cols">
          <LoadingProductCardComponent v-for="n in 8" :key="n" />
        </div>
      </section>
    </section>

    <!--    <section>-->
    <!--      <div class="cta-container">-->
    <!--        <ContactComponent />-->
    <!--      </div>-->
    <!--    </section>-->
    <ToastComponent ref="toast" />
  </main>
</template>

<script>
import ProductCardComponent from "@/components/home/ProductCardComponent.vue";
import BigInputSearchComponent from "@/components/home/BigInputSearchComponent.vue";
import ContactComponent from "@/components/shared/ContactComponent.vue";
import LoadingProductCardComponent from "@/components/home/LoadingProductCardComponent.vue";
import InfoBoxComponent from "@/components/shared/InfoBoxComponent.vue";
import { normalizedString } from "@/features/stringUtils";
import OrderInProgressPreviewComponent from "@/components/order/OrderInProgressPreviewComponent.vue";
import ToastComponent from "@/components/shared/ToastComponent.vue";
import { ToastTypes } from "@/features/toastTypes";
import { onMounted, onUnmounted, ref } from "vue";
import GoToPaymentButtonComponent from "@/components/shared/GoToPaymentButtonComponent.vue";

export default {
  setup() {
    const windowWidth = ref(window.innerWidth);

    const handleResize = () => {
      windowWidth.value = window.innerWidth;
    };

    onMounted(() => {
      window.addEventListener("resize", handleResize);
    });

    onUnmounted(() => {
      window.removeEventListener("resize", handleResize);
    });

    return {
      windowWidth,
    };
  },
  components: {
    GoToPaymentButtonComponent,
    ToastComponent,
    OrderInProgressPreviewComponent,
    InfoBoxComponent,
    LoadingProductCardComponent,
    ProductCardComponent,
    BigInputSearchComponent,
    // eslint-disable-next-line vue/no-unused-components
    ContactComponent,
  },
  data() {
    return {
      filter: "",
      intervalId: null,
      isFetchingUserAddresses: false,
    };
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  async mounted() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    await Promise.all([
      this.$store.dispatch("product/fetchAllProducts"),
      this.$store.dispatch("order/fetchOrdersInProgress"),
    ]);

    // todo: optimize backend calls
    // this.intervalId = setInterval(() => {
    //   this.$store.dispatch("order/fetchOrdersInProgress");
    // }, 30000);

    if (this.$store.state.auth.successImpersonate) {
      this.$refs.toast.fire(
        ToastTypes.Success,
        "Usuario impersonado exitosamente"
      );
      this.$store.dispatch("auth/setSuccessImpersonate", false);
    }
  },
  beforeUnmount() {
    // clearInterval(this.intervalId);
  },
  unmounted() {
    window.removeEventListener("scroll", this.handleScroll);
    this.$store.dispatch("product/setShowInputSearchInHeader", false);
  },
  computed: {
    products() {
      return this.$store.state.product.products;
    },
    showInputSearchInHeader() {
      return this.$store.state.product.showInputSearchInHeader;
    },
    pendingOrders() {
      return this.$store.state.order.pendingOrders;
    },
    cartLength() {
      return this.$store.state.order.newOrder.items.length;
    },
    filteredProductsLength() {
      const filter = this.$store.state.product.searchFilter;

      if (filter) {
        const filteredProducts = this.$store.state.product.products.filter(
          (product) => {
            return normalizedString(product.name).includes(
              normalizedString(filter)
            );
          }
        );

        return filteredProducts.length;
      } else {
        return -1;
      }
    },
    isMobile() {
      return this.windowWidth < 704;
    },
  },
  methods: {
    handleScroll() {
      this.$store.dispatch(
        "product/setShowInputSearchInHeader",
        window.scrollY > this.bigInputSearchYPosition()
      );
    },
    bigInputSearchYPosition() {
      const element = this.$refs.inputSearchContainer;
      return element.offsetTop;
    },
  },
};
</script>

<style>
.section-hero {
  padding: 0 0 6.2rem;
  background-size: cover;
}

.heading-primary {
  display: flex;
  gap: 4.5rem;
}

.input-search-container {
  height: 5rem;
  display: flex;
  gap: 2rem;
  width: 100%;
  justify-content: center;
}

.order-in-progress-container {
  margin-top: 4rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.products-not-found-info {
  display: flex;
  justify-content: center;
}

.more-orders-description,
.more-orders-description:visited {
  font-size: 1.6rem;
  color: #1864ab;
  text-align: center;
}

/* Below 704PX (Smaller Tablets) */
@media (max-width: 44em) {
  #btn-cta-home {
    display: none;
  }

  .hero-specs {
    flex-direction: column;
    gap: 1.2rem !important;
    align-self: flex-end;
    display: none !important;
  }

  .section-hero {
    padding-bottom: 4.4rem !important;
  }

  .hero-description-box {
    margin-top: 4.4rem !important;
  }
}

/* Below 544PX (Phones) */
@media (max-width: 34em) {
  .section-hero {
    padding-bottom: 2.2rem !important;
  }
}
</style>
